import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const UxcoursePage = ({ data, location: { pathname } }) => (
  <Layout
      
    >
      <div>
        <section className="p-0 h-100">
          <div className="vh-100">

            <iframe
              style={{ border: "0px solid rgba(255, 255, 255, 1);", 'margin-top':"-40px" }}
              width="100%"
              height="100%"        
              // src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FfhBQuceCuMphHyc068nBg8%2FAccessibility-Prototype%3Fpage-id%3D0%253A1%26node-id%3D238%253A1090%26viewport%3D357%252C48%252C0.75%26scaling%3Dcontain%26hotspot-hints%3D0%26hide-ui%3D1"
              // src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FfhBQuceCuMphHyc068nBg8%2FAccessibility-Prototype%3Fpage-id%3D0%253A1%26node-id%3D238%253A1090%26viewport%3D357%252C48%252C0.05%26scaling%3Dcontain%26hotspot-hints%3D0%26hide-ui%3D1"

              src="https://v1.embednotion.com/embed/61a661b1646a4bcb95b776f8d8a4dbbb"

            allowfullscreen>
            </iframe>
          </div>

          {/* <div className="d-block d-lg-none pt-6">
            <div className="container pb-2">
                <div className="row align-items-center justify-content-between o-hidden">
                  <div className="lead col-10 col-lg-7 my-0 mx-auto text-center">
                    <h1>At the moment our prototype is only available on desktop 🙄</h1>
                    <p>In the meantime, check the <a href="https://adamfard.com/blog/accessibility">impact of accessibility</a> on your products.</p>
                  </div>
                </div>
            </div>

          </div> */}
        </section>
    </div>

    </Layout>


);

export default UxcoursePage;
